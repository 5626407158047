// dependencies
import axios from 'axios';
import DB from '@/lib/IndexedDB';
import {debug} from '@/lib/Debug';

// available indexed databases
const DB_MARKETS_OVERVIEW = 'markets-overview';
const DB_MARKET = 'market';
const DB_RESOURCES = 'markets-resources';
const DB_LOCALES = 'market-locales';

const ENV = process.env;
let AXIOS_CONFIG = {};

if (ENV['VUE_APP_API_USER'] !== '' && ENV['VUE_APP_API_PASSWORD'] !== '') {
    AXIOS_CONFIG = {
        ...AXIOS_CONFIG,
        auth: {
            username: ENV['VUE_APP_API_USER'],
            password: ENV['VUE_APP_API_PASSWORD'],
        },
    };
}

/*
 * Get image paths
 * Returns all image paths of an array or an object (recursive).
 */
export const getImagePaths = async (stack) => {
    const imageAttributes = ['url']; // adjust this array if the api contains different variations of field naming.
    const paths = [];
    const getRecursive = async (items) => {
        for (const item of Object.keys(items)) {
            if (imageAttributes.includes(item)) {
                paths.push(items[item]);
            }
            if (typeof items[item] === 'object') {
                await getRecursive(items[item]);
            }
        }
    };
    if (Object.keys(stack).length > 0) {
        await getRecursive(stack);
    }
    return paths;
};


/*
 * Load resource
 * Checks if resource is offline available otherwise it will trigger the download for this resource
 */
export const loadResource = async (key) => {
    debug(`Start loading resource ${key}`);
    const localDatabase = await DB(DB_RESOURCES);
    let localResource = await localDatabase.getEntry(key);
    if (!localResource) {
        localResource = await saveResource(key);
    }
    return localResource.blob;
};

/*
 * Save resource
 * Downloads the resource by using fetch, this result will be converted to an blob and saved in our IndexedDB
 */
export const saveResource = async (key) => {
    debug(`Start saving resource (${key})`);
    const offlineSupport = process.env['VUE_APP_OFFLINE_SUPPORT'] === 'true';

    const resourceObj = {
        id: key,
        blob: null,
    };

    await axios({
        method: 'GET',
        url: key,
        responseType: 'blob',
    }).then(async resp => {
        resourceObj.blob = await resp.data;
        if (offlineSupport) {
            const localDatabase = await DB(DB_RESOURCES);
            await localDatabase.put(resourceObj);
        }
    }).catch(err => {
        console.log(err);
    });

    return resourceObj;
};


/*
 * Load locales
 */
export const loadLocales = async (locale) => {
    debug('Start loading locales');

    let locales = {};
    let updateAvailable = false;

    // get translations
    const localDatabase = await DB(DB_LOCALES);
    const localLocales = await localDatabase.getEntry('locales');
    const offlineSupport = process.env['VUE_APP_OFFLINE_SUPPORT'] === 'true';

    // check for offline saved locales
    if (localLocales) {
        debug(`Locales available - fetch last modified date`);
        updateAvailable = await fetchLocalesUpdateInfoFromApi(locale);
        updateAvailable = Number(updateAvailable.last_modified);
    }

    // update data if its out of date or is not offline available yet
    if (!localLocales || (localLocales && updateAvailable && updateAvailable > localLocales.last_updated)) {
        debug(`Locale is not available or out of date - update it`);
        locales = await fetchLocalesFromApi();
        if (offlineSupport) {
            locales = await saveLocalesOffline(locales);
        }
    } else {
        debug(`Set market overview from IndexedDB`);
        locales = localLocales;
    }

    return locales;
};

/*
 * Save locales offline
 */
export const saveLocalesOffline = async (locales) => {
    const localDatabase = await DB(DB_LOCALES);
    const localeObj = {
        id: 'locales',
        last_updated: Math.floor(Date.now() / 1000),
        langs: locales,
    };
    await localDatabase.put(localeObj);
    return localeObj;
};


/*
 * Load market overview
 * Load market overview by given locale either from indexedDB
 */
export const loadMarketsOverview = async (locale) => {
    debug('Start loading markets overview');

    let marketOverview = {};
    let updateAvailable = false;

    // get market overview in specific language
    const localDatabase = await DB(DB_MARKETS_OVERVIEW);
    const localMarkets = await localDatabase.getEntry(locale);
    const offlineSupport = process.env['VUE_APP_OFFLINE_SUPPORT'] === 'true';

    // check for offline saved market
    if (localMarkets) {
        debug(`Market overview available - fetch last modified date (language: ${locale})`);
        updateAvailable = await fetchMarketsOverviewUpdateInfoFromApi(locale);
        updateAvailable = Number(updateAvailable.last_modified);
    }

    if (!localMarkets || (localMarkets && updateAvailable && updateAvailable > localMarkets.last_updated)) {
        // update data if its out of date or is not offline available yet
        debug(`Market overview is not available or out of date - update it (language: ${locale})`);
        marketOverview = await fetchMarketsOverviewFromApi(locale);
        if (offlineSupport) {
            await saveMarketsOverviewOffline(locale, marketOverview);
        }
    } else {
        debug(`Set market overview from IndexedDB (language: ${locale})`);
        marketOverview = localMarkets.markets;
    }

    return marketOverview;
};

/*
 * Save market overview offline
 * Saves the market overview in the indexedDB
 */
export const saveMarketsOverviewOffline = async (locale, markets) => {
    const localDatabase = await DB(DB_MARKETS_OVERVIEW);
    const marketObj = {
        id: locale,
        last_updated: Math.floor(Date.now() / 1000),
        markets: markets,
    };
    await localDatabase.put(marketObj);
    return marketObj;
};


/*
 * Load Market
 * Load market by given locale and identifier either from IndexedDB
 *
 */

export const loadMarket = async (locale, identifier) => {
    debug('Start loading market');

    let market = {};
    let updateAvailable = false;

    // get market overview in specific language
    const localDatabase = await DB(DB_MARKET);
    const localMarket = await localDatabase.getEntry(`${locale}_${identifier}`);
    const offlineSupport = process.env.VUE_APP_OFFLINE_SUPPORT === 'true';

    // check for offline saved market
    if (localMarket) {
        debug(`Market available - fetch last modified date (language: ${locale}, identifier: ${identifier})`);
        updateAvailable = await fetchMarketUpdateInfoFromApi(locale, identifier);
        updateAvailable = Number(updateAvailable.last_modified);
    }

    // update data if its out of date or is not offline available yet
    if (!localMarket || (localMarket && updateAvailable && updateAvailable > localMarket.last_updated)) {
        debug(`Market is not available or out of date - update it (language: ${locale}, identifier: ${identifier})`);
        market = await fetchMarketFromApi(locale, identifier);
        if (offlineSupport) {
            await saveMarketOffline(locale, identifier, market);
        }
    } else {
        debug(`Set market from IndexedDB (language: ${locale}, identifier: ${identifier})`);
        market = localMarket.market;
    }

    return market;
};

/*
 * Save market offline
 * Saves the market in the indexedDB
 */
export const saveMarketOffline = async (locale, identifier, market) => {
    const localDatabase = await DB(DB_MARKET);
    const marketObj = {
        id: `${locale}_${identifier}`,
        last_updated: Math.floor(Date.now() / 1000),
        market: market,
    };
    await localDatabase.put(marketObj);
    return marketObj;
};


/*
 * API calls
 */
export const fetchMarketsOverviewFromApi = async (locale) => {
    debug(`Start fetching market overview from API (language: ${locale})`);

    const hasLanguageSwitch = process.env['VUE_APP_LANGUAGE_SWITCHER'] === 'true';
    const url = hasLanguageSwitch ? `${process.env['VUE_APP_API']}${locale}/api/markets` : `${process.env['VUE_APP_API']}/api/markets`;

    return await axios.get(url, AXIOS_CONFIG)
        .then(resp => {
            return resp.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const fetchMarketsOverviewUpdateInfoFromApi = async (locale) => {
    debug(`Start fetching update info for market overview (language: ${locale})`);

    const hasLanguageSwitch = process.env['VUE_APP_LANGUAGE_SWITCHER'] === 'true';
    const url = hasLanguageSwitch ? `${process.env['VUE_APP_API']}${locale}/api/markets/updated` : `${process.env['VUE_APP_API']}/api/markets/updated`;

    return await axios.get(url, AXIOS_CONFIG)
        .then(resp => {
            return resp.data;
        })
        .catch(err => {
            console.log(err);
        });
};
export const fetchMarketFromApi = async (locale, identifier) => {
    debug('Start fetching specific market from API');

    const hasLanguageSwitch = process.env['VUE_APP_LANGUAGE_SWITCHER'] === 'true';
    const url = hasLanguageSwitch ? `${process.env['VUE_APP_API']}${locale}/api/markets/${identifier}` : `${process.env['VUE_APP_API']}/api/markets/${identifier}`;

    return await axios.get(url, AXIOS_CONFIG)
        .then(resp => {
            return resp.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const fetchMarketUpdateInfoFromApi = async (locale, identifier) => {
    debug(`Start fetching update info for market (language: ${locale}, identifier: ${identifier})`);

    const hasLanguageSwitch = process.env['VUE_APP_LANGUAGE_SWITCHER'] === 'true';
    const url = hasLanguageSwitch ? `${process.env['VUE_APP_API']}${locale}/api/markets/${identifier}/updated` : `${process.env['VUE_APP_API']}/api/markets/${identifier}/updated`;

    return await axios.get(url, AXIOS_CONFIG)
        .then(resp => {
            return resp.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const fetchLocalesFromApi = async () => {
    debug(`Start fetching locales from API`);

    return await axios.get(`${process.env['VUE_APP_API']}/api/locales`, AXIOS_CONFIG)
        .then(resp => {
            return resp.data;
        });
};

export const fetchLocalesUpdateInfoFromApi = async (locale) => {
    debug(`Start fetching update info for locales`);

    const hasLanguageSwitch = process.env['VUE_APP_LANGUAGE_SWITCHER'] === 'true';
    const url = hasLanguageSwitch ? `${process.env['VUE_APP_API']}${locale}/api/locales/updated` : `${process.env['VUE_APP_API']}/api/locales/updated`;

    return await axios.get(url, AXIOS_CONFIG)
        .then(resp => {
            return resp.data;
        });
};
