<template>
    <div class="paragraph-bulletpoint-list">
        <h3 class="paragraph-bulletpoint-list__title">{{ title }}</h3>
        <ul v-for="item in paragraph.bulletpoints"
            class="paragraph-bulletpoint-list__items">
            <li class="paragraph-bulletpoint-list__item">
                {{ item.value }}
            </li>
        </ul>
    </div>
</template>
<script>
import ParagraphsMixin from '@/mixins/Paragraphs';

export default {
    name: 'ParagraphBulletpointList',
    mixins: [
        ParagraphsMixin,
    ],
};
</script>