import {createRouter, createWebHashHistory} from 'vue-router';
import MarketView from '@/views/MarketView.vue';
import TouchpointView from '@/views/TouchpointView';
import MarketsOverviewView from '@/views/MarketsOverviewView';
import RedirectByBrowserLang from '@/views/RedirectByBrowserLang';

const routes = [

    {
        path: '/',
        name: 'redirect-by-lang',
        component: RedirectByBrowserLang,
    },
    {
        path: '/:locale',
        name: 'marketoverview',
        component: MarketsOverviewView,
    },
    {
        path: '/:locale/:market',
        name: 'market',
        component: MarketView,
        children: [
            {
                path: 'touchpoint/:id',
                name: 'touchpoint',
                component: TouchpointView,
            },
        ],

    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;
