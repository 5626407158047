<template>
    <template v-for="market in markets_overview">
        <router-link :to="`/${locale}/${market.machine_name}`">
            <div class="market-teaser">
                <img :src="market.image"
                     alt=""
                     width="400">
                <br>
                {{ market.title }}
                {{ market.machine_name }}
            </div>
        </router-link>
    </template>
</template>

<script>

import {mapActions, mapState} from 'vuex';

export default {
    name: 'ChooseMarket',
    methods: {
        ...mapActions(['setLocale']),
    },
    computed: {
        ...mapState(['markets_overview', 'active_locale']),
        locale() {
            return this.$route.params.locale;
        },
    },
    watch: {
        markets_overview() {
            if (Object.keys(this.markets_overview).length === 1) {
                const key = Object.keys(this.markets_overview)[0];
                this.$router.push(`/${this.locale}/${this.markets_overview[key].machine_name}`);
            }
        }
    },
};

</script>