<template>
    <nav class="language-switch">
        <template v-if="activeMarket" v-for="locale in locales.langs">
            <a href="#"
               class="language-switch__link"
               :class="{'language-switch__link--active': locale.shortcut === active_locale }"
               v-if="activeMarket && locale.markets.includes(activeMarket)"
               @click.prevent="handleLanguageSwitch(locale)">
                {{ locale.shortcut }}
            </a>
        </template>
        <template v-if="!activeMarket" v-for="locale in locales.langs">
            <a href="#"
               class="language-switch__link"
               @click.prevent="handleLanguageSwitch(locale)">
                {{ locale.name }}
            </a>
        </template>
    </nav>
</template>
<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'LanguageSwitch',
    computed: {
        ...mapState(['locales', 'active_locale']),
        activeMarket() {
            return this?.$route?.params?.market;
        },
    },
    methods: {
        ...mapActions(['setLocale']),
        async handleLanguageSwitch(locale) {
            if (locale) await this.setLocale(locale);

            await this.$router.push({
                ...this.$route,
                params: {
                    locale: locale.shortcut,
                },
            });

            window.location.reload();
        },
    },
};
</script>