<template>
    <transition-group name="fade" @enter="$emit('ready')">
        <img v-show="loaded"
             key="img"
             :src="img.src"
             @load="onLoad"
             class="cache-img" />
        <div v-show="!loaded"
             key="placeholder"
             class="cache-img__placeholder"
             ref="placeholder"></div>
    </transition-group>
</template>
<script>
import {loadResource} from '@/lib/DataLoader';

export default {
    name: 'CacheImage',
    emits: ['ready'],
    props: {
        path: {
            default: null,
        },
    },
    data() {
        return {
            img: {
                src: '',
            },
            loaded: false,
        };
    },
    methods: {
        async onLoad() {
            this.loaded = true;
        },
        async loadImg(url) {
            this.img = new Image();
            this.img.src = url;
        },
        isHidden(el) {
            return (el && el.offsetParent === null);
        },
    },
    watch: {
        async path() {
            if (!this.isHidden(this.$refs.placeholder)) {
                const resource = await loadResource(this.path);
                const url = URL.createObjectURL(resource);
                await this.loadImg(url);
            }
        },
    },
    async mounted() {
        const resource = await loadResource(this.path);
        const url = URL.createObjectURL(resource);
        await this.loadImg(url);
    },
};
</script>
