const DEBUG_MODE = process.env['VUE_APP_DEBUG'];
const ENGINE_NAME = 'MARKETS';

export const debug = (...args) => {
    if (DEBUG_MODE === 'true') {
        const d = new Date();
        const dateObj = {
            day: String(d.getDay()).padStart(2, 0),
            month: String(d.getMonth() + 1).padStart(2, 0),
            year: String(d.getFullYear()),
            hour: String(d.getHours()).padStart(2, 0),
            minute: String(d.getMinutes()).padStart(2, 0),
            second: String(d.getSeconds()).padStart(2, 0),
            milliseconds: String(d.getMilliseconds()),
        };
        const dateString = `${dateObj.day}.${dateObj.month}.${dateObj.year} - ${dateObj.hour}:${dateObj.minute}:${dateObj.second}:${dateObj.milliseconds}`;
        console.log(`%c${ENGINE_NAME} %c${dateString}`, 'color:gray', 'color:orange');
        for (let arg in args) {
            if (typeof args[arg] === 'string') {
                console.log(`%c> %c${args[arg]}`, 'color:orange', 'color:lightblue');
            } else {
                console.log(`%c> [${typeof args[arg]}]`, 'color:orange', args[arg]);
            }
        }
    }
};