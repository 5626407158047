<template>
    <div class="markets-overview-view">
        <ChooseMarket />
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import ChooseMarket from '@/components/ChooseMarket';

export default {
    name: 'MarketOverviewView',
    components: {
        ChooseMarket,
    },
    methods: {
        ...mapActions(['loadMarketsOverview', 'setLocale']),
    },
    async mounted() {
        // set current locale on mount
        const locale = this?.$route?.params?.locale;
        if (locale) await this.setLocale(locale);

        // load market overview
        await this.loadMarketsOverview();
    },
};
</script>
