<template>
    <div class="paragraph-media-text">
        <div class="paragraph-media-text__container">
            <h3 class="paragraph-media-text__title">{{ title }}</h3>
            <p class="paragraph-media-text__description"
               v-html="paragraph.teaser.value"></p>
            <media-slider :slides="paragraph.media"></media-slider>
        </div>
    </div>
</template>
<script>
import ParagraphsMixin from '@/mixins/Paragraphs';
import MediaSlider from '@/components/MediaSlider';

export default {
    name: 'ParagraphMediaText',
    mixins: [
        ParagraphsMixin,
    ],
    components: {
        MediaSlider,
    },
};
</script>