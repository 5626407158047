export default {
    props: {
        paragraph: {
            type: Object,
            default: {},
        },
        items: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
            default: '',
        },
    },
};