<template>
    <div></div>
</template>
<script>

import {mapActions} from 'vuex';

export default {
    name: 'RedirectByBrowserLang',
    methods: {
        ...mapActions(['setLocale']),
    },
    async mounted() {
        // evaluate locale by navigatior
        const lang = navigator.language.substr(0, 2);
        await this.setLocale(lang);

        // redirect
        await this.$router.push({name: 'marketoverview', params: {locale: lang}});
    },
};
</script>