<template>
    <div class="touchpoint-aside">
        <div class="touchpoint-aside__medium-content">
            <slot name="mobile"></slot>
        </div>

        <div class="touchpoint-aside__head">
            <div class="touchpoint-aside__container">
                <div v-if="touchpoint.title"
                     class="touchpoint-aside__title">
                    {{ touchpoint.title }}
                    <transition name="touchpoint-aside-subtitle">
                        <div class="touchpoint-aside__subtitle"
                             v-if="!!selected">{{ selected.title }}
                        </div>
                    </transition>
                </div>
                <div class="touchpoint-aside__actions">
                    <action-button @click.native.prevent="close"
                                   class="touchpoint-aside__close"
                                   icon="times"/>
                </div>
            </div>
        </div>

        <div class="touchpoint-aside__small-content">
            <slot name="mobile"></slot>
        </div>

        <div class="touchpoint-aside__content">
            <paragraphs v-if="selected === null"
                        :items="touchpoint.content"/>
            <paragraphs v-else
                        :items="selected.content"/>

        </div>

    </div>
</template>
<script>
import ActionButton from '@/components/ActionButton';
import Paragraphs from '@/components/Paragraphs';

export default {
    name: 'TouchpointAside',
    components: {
        ActionButton,
        Paragraphs,
    },
    props: {
        close: {
            default: null,
        },
        selected: {
            default: null,
        },
        touchpoint: {
            default: null,
        },
    },
};
</script>