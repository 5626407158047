// 3rd
import {createApp} from 'vue';
import { dragscrollNext } from "vue-dragscroll";

// service worker
import './registerServiceWorker';

// router & store
import router from './router';
import store from './store';

// components
import App from './App';

// styling
import(`@/assets/styling/${process.env['VUE_APP_CSS_THEME']}/app.scss`);


createApp(App)
    .use(store)
    .use(router)
    .directive('drag-scroll', dragscrollNext)
    .mount('#app');
