<template>
    <div class="paragraph-link-list">
        <h3 class="paragraph-link-list__title">{{ paragraph.title }}</h3>
        <ul class="paragraph-link-list__links">
            <li v-for="item in paragraph.links"
                class="paragraph-link-list__item">
                <a :href="item.uri"
                   :target="item.internal ? '_self' : '_blank'"
                   class="paragraph-link-list__link">
                    <cache-img v-if="item.image"
                               class="paragraph-link-list__image"
                               :path="item.image" />
                    {{ item.title }}
                    <div class="paragraph-link-list__icon"></div>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
import ParagraphsMixin from '@/mixins/Paragraphs';
import CacheImg from '@/components/CacheImg';

export default {
    name: 'ParagraphLinkList',
    components: {
        CacheImg,
    },
    mixins: [
        ParagraphsMixin,
    ],
};
</script>