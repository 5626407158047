<template>
    <div v-if="navigation"
         class="touchpoint-slider">
        <button class="touchpoint-slider__btn"
                @click.prevent="prev(navigation.prev_link_id)">
            <span class="touchpoint-slider__btn-text">
                {{ navigation.prev_title }}
            </span>
        </button>
        <div class="touchpoint-slider__title">{{ currentTouchpoint.title }}</div>
        <button class="touchpoint-slider__btn"
                @click.prevent="next(navigation.next_link_id)">
            <span class="touchpoint-slider__btn-text">
                {{ navigation.next_title }}
            </span>
        </button>
    </div>
</template>
<script>
export default {
    name: 'TouchpointSlider',
    props: {
        currentTouchpoint: {
            default: null,
        },
        touchpoints: {
            type: Array,
            default: [],
        },
    },
    methods: {
        prev(id) {
            this.$router.push({name: this.$route.name, params: {id: id}});
        },
        next(id) {
            this.$router.push({name: this.$route.name, params: {id: id}});
        },
    },
    computed: {
        navigation() {
            if (this.touchpoints.length <= 0 || this.currentTouchpoint === null) return null;

            let tps = this.touchpoints.map(tp => {
                if (tp.type !== 'external') {
                    return tp;
                } else {
                    return null;
                }
            });
            tps = tps.filter(n => n);

            let prevIndex = 0;
            let nextIndex = 0;
            const currentIndex = tps.findIndex((item) => {
                return item.machine_name === this.currentTouchpoint.machine_name;
            });

            if (currentIndex - 1 < 0) {
                prevIndex = tps.length - 1
            } else {
                prevIndex = currentIndex - 1;
            }

            if (currentIndex + 1 > tps.length - 1) {
                nextIndex = 0;
            } else {
                nextIndex = currentIndex + 1;
            }

            return {
                prev_title: tps[prevIndex].title,
                prev_link_id: tps[prevIndex].machine_name,
                next_title: tps[nextIndex].title,
                next_link_id: tps[nextIndex].machine_name,
            };
        },
    },
};
</script>