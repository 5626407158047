<template>
    <div class="touchpoint-view">
        <div class="touchpoint-view__bg"
             @click.prevent="closeTouchpointView"></div>

        <div class="touchpoint-view__content">
            <div class="touchpoint-view__map-container">
                <touchpoint-map @input="handleSelectTouchpoint"
                                :scrollable="false"
                                :selected="selectedTouchpoint"
                                :touchpoints="touchpoint.second_level"
                                :background="touchpoint.image"></touchpoint-map>
                <touchpoint-slider :current-touchpoint="touchpoint"
                                   :touchpoints="market.routes" />
            </div>
        </div>

        <touchpoint-aside :close="closeTouchpointView"
                          :selected="selectedTouchpoint"
                          :touchpoint="touchpoint">
            <template #mobile>
                <touchpoint-map @input="handleSelectTouchpoint"
                                :scrollable="false"
                                :selected="selectedTouchpoint"
                                :touchpoints="touchpoint.second_level"
                                :background="touchpoint.image"></touchpoint-map>
                <touchpoint-slider :current-touchpoint="touchpoint"
                                   :touchpoints="market.routes" />
            </template>
        </touchpoint-aside>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import TouchpointMap from '@/components/TouchpointMap';
import TouchpointAside from '@/components/TouchpointAside';
import TouchpointSlider from '@/components/TouchpointSlider';

export default {
    name: 'TouchpointView',
    components: {
        TouchpointMap,
        TouchpointAside,
        TouchpointSlider,
    },
    data() {
        return {
            selectedTouchpoint: null,
        };
    },
    methods: {
        handleSelectTouchpoint($event) {
            if (this.selectedTouchpoint === $event) {
                this.selectedTouchpoint = null;
            } else {
                this.selectedTouchpoint = $event;
            }
        },
        closeTouchpointView() {
            const redirectTo = {
                name: 'market',
                params: {
                    market: this?.$route?.params?.market,
                },
            };
            this.$router.push(redirectTo);
        },
    },
    computed: {
        ...mapGetters(['getTouchpointsByMachineName']),
        ...mapState(['market']),
        touchpoint() {
            const tpId = this?.$route?.params?.id;
            return this.getTouchpointsByMachineName(tpId);
        },
    },
    watch: {
        touchpoint() {
            this.selectedTouchpoint = null;
        },
    },
};
</script>
