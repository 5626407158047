<template>
    <div class="paragraphs">
        <transition-group name="paragraphs" appear>
            <div class="paragraphs__item" v-for="(item, index) in items"
                 :key="`${item.title}${index}${Math.random()}`">
                <component :paragraph="item"
                           :title="item.title"
                           :is="evaluateParagraph(item)"></component>
                <hr v-if="items.length - 1 > index"
                    class="paragraphs__divider" />
            </div>
        </transition-group>
    </div>
</template>
<script>

import ParagraphBulletpointList from '@/components/ParagraphBulletpointList';
import ParagraphLinkList from '@/components/ParagraphLinkList';
import ParagraphProductList from '@/components/ParagraphProductList';
import ParagraphMediaText from '@/components/ParagraphMediaText';

export default {
    name: 'Paragraphs',
    props: {
        items: {
            type: Object,
            default: [],
        },
    },
    components: {
        ParagraphBulletpointList,
        ParagraphLinkList,
        ParagraphProductList,
        ParagraphMediaText,
    },
    data() {
        return {
            paragraphMappings: [
                {
                    key: 'bulletpointlist',
                    component: 'paragraph-bulletpoint-list',
                },
                {
                    key: 'productlist',
                    component: 'paragraph-product-list',
                },
                {
                    key: 'touchpoint_media_text',
                    component: 'paragraph-media-text',
                },
                {
                    key: 'touchpoint_graphic_linklist',
                    component: 'paragraph-link-list',
                },
            ],
        };
    },
    methods: {
        evaluateParagraph(item) {
            const filtered = this.paragraphMappings.filter((i) => {
                return i.key === item.type;
            });
            return filtered[0].component;
        },
    },
};
</script>