<template>
    <div class="paragraph-product-list">
        <h3 class="paragraph-product-list__title">{{ title }}</h3>
        <a v-for="item in paragraph.items"
           href="#"
           class="paragraph-product-list__teaser">
            <div class="paragraph-product-list__img">
                <cache-img v-if="item.image" :path="item.image" />
            </div>
            <div class="paragraph-product-list__teaser-title">
                {{ item.title }}
            </div>
        </a>
    </div>
</template>
<script>
import ParagraphsMixin from '@/mixins/Paragraphs';
import CacheImg from '@/components/CacheImg';

export default {
    name: 'ParagraphProductList',
    components: {
        CacheImg,
    },
    mixins: [
        ParagraphsMixin,
    ],
};
</script>