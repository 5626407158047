<template>
    <video v-if="resource"
           class="cache-video"
           controls
           @load="$emit('ready')">
        <source :src="resource"
                type="video/mp4">
        Your browser does not support HTML video.
    </video>

</template>
<script>
import {loadResource} from '@/lib/DataLoader';

export default {
    name: 'CacheVideo',
    props: {
        path: {
            default: null,
        },
    },
    data() {
        return {
            resource: null,
        };
    },
    watch: {
        async src() {
            this.resource = URL.createObjectURL(await loadResource(this.path));
        },
    },
    async mounted() {
        this.resource = URL.createObjectURL(await loadResource(this.path));
    },
};
</script>